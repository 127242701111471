import { Button, Card, Col, Row } from "react-bootstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';
import '../style.scss'
import StaticPagesServices from "../../../../../services/StaticPagesService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";
import uploadImg from "../../../../../images/upload-img.png";
import Loader from "../../../../common/Loader";
import BaseService from "../../../../../services/BaseService";
import { useLocation } from "react-router-dom";

const Static = () =>{
    const [formData, setFormData] =useState([
        {
            image: '',
            loading: false,
            title_en:'',
            title_ar:'',
            description_ar: EditorState.createEmpty(),
            description_en: EditorState.createEmpty(),
        }
    ])
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    // const [isEdit, setIsEdit] = useState()
    const [loading, setLoading] = useState()
    const location = useLocation()
    const [submitLoading, setSubmitLoading] = useState(false)
    const staticPagesServices = new StaticPagesServices()

    const changeInput = (e,name,index) =>{
        let update = formData.map((item,ind)=>{
            if(index === ind){
                return{
                    ...item,
                    [name]: e.target.value
                }
            } else{
                return item
            }
        })
        setFormData(update)
    }

    useEffect(()=>{
        let params = {type: location?.pathname === '/privacy' ? 'Privacy' : location?.pathname === '/product/rules' ? 'faqs' : 'about'}
        setLoading(true)
        staticPagesServices.getList(params).then(res=>{
            if(res?.status === 200){
                if(res.data.data?.length === 0){
                    // setIsEdit(false)
                } else {
                    let data = res.data.data?.map(item =>{
                        return{
                            image: item.image,
                            loading: false,
                            title_en: item.title_en,
                            title_ar: item.title_ar,
                            description_ar: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_ar))),
                            description_en: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_en))),
                        }
                    })
                    setFormData(data)
                }
            }
            setLoading(false)
        })
    },[location?.pathname])

    const submit = () =>{
        let data ={
            type: location?.pathname === '/privacy' ? 'Privacy' : location?.pathname === '/product/rules' ? 'faqs' : 'about',
            static_page: formData.map(res=>{
                let en = draftToHtml(convertToRaw(res.description_en.getCurrentContent()))
                let ar = draftToHtml(convertToRaw(res.description_ar.getCurrentContent()))
                let info = {
                    ...res,
                    description_en: en,
                    description_ar: ar
                }
                delete info.loading
                if(location?.pathname === '/product/rules') info['image'] = 'https://www.google.com/url?sa=i&url=http%3A%2F%2Fwww.privatelayer.com%2Fassets%2Fimg-temp%2F300x300%2F&psig=AOvVaw23vCZmmqNNLPBRkjtnz71X&ust=1734280465906000&source=images&cd=vfe&opi=89978449&ved=0CBQQjRxqFwoTCKip06rYp4oDFQAAAAAdAAAAABAE'

                return info
            })
        }
        setSubmitLoading(true)
        staticPagesServices.create(data).then(res=>{
            if(res?.status === 201){
                toast.success("Update Data Successfullly")
                // setIsEdit(true)
            }
            setSubmitLoading(false)
        })
    }

    const fileHandler = (e,index) => {
        if(e.target.files?.length === 0){
            return
        }

        let files = e.target.files
        const filesData = Object.values(files)
 
        if (filesData.length) {
            let update = formData?.map((item, ind)=> {
                if(index === ind){
                    return {
                        ...item,
                        loading: true
                    }
                } else {
                    return item
                }
            })
            setFormData(update)
            
            new BaseService().postUpload(filesData[0]).then(res=>{
                if(res.data.status){
                    let update = formData?.map((item, ind)=> {
                        if(index === ind){
                            return {
                                ...item,
                                loading: false,
                                image: res.data.url
                            }
                        } else {
                            return item
                        }
                    })
                    setFormData(update)
                }
            })
        }
    }

    if(loading){
        return <Card className="py-5" style={{height: '300px'}}>
            <Card.Body>
                <Loader />
            </Card.Body>
      </Card>
    }

    return<>
    <Card>
        <Card.Body>
        <AvForm onValidSubmit={submit} className='about-us'>
            {formData?.map((item, index)=>{
                return <Row className="position-relative" key={index} style={{borderBottom: '2px solid #dedede', paddingBottom: '5rem' ,marginBottom: '2rem'}}>
                    {index > 0 && <button className="delete border-0"  type="button" onClick={()=>{
                        let update = formData.filter((_,ind) => ind !== index)
                        setFormData(update)
                    }}>
                        <i className="la la-times"></i>
                    </button>}
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={Translate[lang].english_title}
						name ={`title_en${index}`}
						type="text" 
						value={item.title_en}
                        // disabled={isEdit}
						validate={{
							required: {
								value:true,
								errorMessage: Translate[lang].field_required
							},
						}}
						placeholder={Translate[lang].english_title}
						onChange={(e) => changeInput(e,'title_en',index)}
					/>
				</Col>
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={Translate[lang].arabic_title}
					    name ={`title_ar${index}`}
						type="text" 
                        // disabled={isEdit}
						value={item.title_ar}
						validate={{
							required: {
								value:true,
								errorMessage: Translate[lang].field_required
							},
						}}
						placeholder={Translate[lang].arabic_title}
						onChange={(e) => changeInput(e,'title_ar',index)}
					/>
				</Col>
                <Col md={6} className="form-group" style={{marginBottom: '8rem'}}>
                    <label className="d-block">{Translate[lang].english_description}</label>
                    <div className="editorField">
                        <Editor
                            // editorState ={editorState}
                            editorState ={item.description_en}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                // if(isEdit){
                                //     return
                                // }
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_en: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group"  style={{marginBottom: '8rem'}}>
                    <label className="d-block">{Translate[lang].arabic_description}</label>
                    <div className="editorField">
                        <Editor
                            // editorState ={editorState}
                            editorState ={item.description_ar}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                // if(isEdit){
                                //     return
                                // }
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_ar: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                {(location?.pathname === '/privacy' || location?.pathname === "/about-us") && <Col md={6}>
                    <div className='form-group w-100'>
                        <label className="m-0">{Translate[lang]?.image}</label>
                        <div className="image-placeholder" style={{maxWidth: 'fit-content'}}>	
                            <div className="avatar-edit h-100">
                                <input 
                                    type="file" 
                                    className="d-block w-100 h-100 cursor-pointer" 
                                    style={{opacity: '0'}}
                                    onChange={(e) => fileHandler(e, index)}
                                /> 					
                            </div>
                            <div className="avatar-preview2 m-auto">
                                <div>
                                {(!!item?.image && !item?.loading) && <img src={item.image} alt='icon'
                                        className='w-100 h-100' style={{borderRadius: '30px'}} 
                                    />}
                                {(!item?.image && !item?.loading) && 
                                    <img
                                        src={uploadImg} alt='icon'
                                        style={{
                                            width: '80px',
                                            height: '80px',
                                        }}
                                    />}
                                {(item?.loading) && <Loader />}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>}
            </Row>
            })}
            
            {isExist('static_pages') && <div className="d-flex mt-5 justify-content-between">
                <Button 
                    // disabled={isEdit}
                    variant="secondary" 
                    onClick={()=>{
                    setFormData([...formData, {
                        title_en:'',
                        title_ar:'',
                        image: '',
                        description_ar: EditorState.createEmpty(),
                        description_en: EditorState.createEmpty(),
                    }])
                }}>
                    {Translate[lang].add_new_value}
                </Button>
                <Button variant="primary" disabled={submitLoading} type="submit">
                    {Translate[lang].submit}
                </Button>
                {/* {isEdit && <Button variant="primary" type="button" onClick={()=>setIsEdit(false)}>
                    {Translate[lang].edit}
                </Button>} */}
            </div>}
        </AvForm>
        </Card.Body>
    </Card>
    </>
}
export default Static;